<template>
	<v-sheet max-width="380" class="mx-auto" color="transparent">
		<v-form @submit.prevent="$v.$invalid ? null : submit()" ref="form" class="my-10">
			<v-text-field
				:error-messages="fieldErrors('password')"
				:append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
				:type="showPwd ? 'text' : 'password'"
				@input="$v.password.$touch()"
				@blur="$v.password.$touch()"
				prepend-inner-icon="lock"
				v-model="password"
				label="Contraseña"
				@click:append="showPwd = !showPwd"
				flat
			/>
			<v-text-field
				type="password"
				label="Confirmación de contraseña"
				v-model="repeatPassword"
				:error-messages="fieldErrors('repeatPassword')"
				@input="$v.repeatPassword.$touch()"
				@blur="$v.repeatPassword.$touch()"
				prepend-inner-icon="enhanced_encryption"
				required
				flat
			/>

			<v-btn block type="submit" color="primary" :disabled="$v.$invalid" :loading="loader"
				>Restablecer contraseña</v-btn
			>
		</v-form>
	</v-sheet>
</template>

<script>
	import { required, sameAs } from 'vuelidate/lib/validators'
	import validationMixin from '@/mixins/validationMixin'
	export default {
		props: [ 'email' ],
		mixins: [ validationMixin ],
		validations: {
			password: { required },
			repeatPassword: {
				sameAsPassword: sameAs( 'password' ),
			},
		},
		validationMessages: {
			password: { required: 'La contraseña es requerida' },
			repeatPassword: {
				sameAsPassword: 'Las contraseñas no coinciden',
			},
		},
		data() {
			return {
				errors: null,
				password: null,
				repeatPassword: null,
				loader: false,
				showPwd: false,
			}
		},
		watch: {
			email() {},
		},
		methods: {
			submit() {
				this.loader = true
				setTimeout( () => {
					this.loader = false
					this.$emit( 'complete', true )
				}, 2000 )
			},
			setPassword() {
				this.$axios
					.post( 'password/reset', {
						email: this.email,
						password: this.password,
						password_confirmation: this.repeatPassword,
						token: this.code,
					} )
					.then( () => {
						this.$swal( {
							title: 'Proceso completado',
							text: 'Haz restablecido tu contraseña exitosamente',
							icon: 'success',
							showCancelButton: false,
							showConfirmButton: true,
						} )
						this.$router.push( '/login' )
					} )
					.catch( () => {} )
			},
		},
	}
</script>
